<template>
    <modal ref="modalFinalizarOp" titulo="Finalizar oportunidad" adicional="Aceptar" @adicional="finalizarOp">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-11">
                <p class="f-10 mb-1">Motivo de finalización:</p>
                <div class="my-auto ml-2">
                    <el-radio v-model="radioTipo" label="1" class="f-12">Ganada</el-radio>
                    <el-radio v-model="radioTipo" label="2" class="f-12">Perdida</el-radio>
                </div>
            </div>
            <div v-if="radioTipo === '1'" class="col-11 mt-3">
                <div class="bg-f5 border br-4 p-2 f-10">
                    <p>Esta oportunidad creará una nueva obra para la gestión de la construcción del o los equipos.</p>
                </div>
            </div>
            <div class="col-11 mt-4">
                <p class="input-label-top">Justificación del motivo</p>
                <el-input v-model="inputJustificacion" type="textarea" placeholder="Justificación" :autosize="{ minRows: 3}" size="small" class="w-100" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            radioTipo: '1',
            inputJustificacion: '',
        }
    },
    methods: {
        toggle(){
            this.$refs.modalFinalizarOp.toggle()
        },
        finalizarOp(){
            this.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
